<template lang="pug">
  transition
    div(v-if="show").wrap-now-loading.f.fh
      div.text-center
        v-progress-circular(indeterminate)
        p.bold.fz14.mt-2
          slot
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

p {
  color: $text_secondary;
}

.wrap-now-loading {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}
/* アニメーション中のスタイル */
.v-leave-active,
.v-enter-active {
  transition: opacity 0.4s;
}

/* 表示アニメーション */
.v-enter {
  opacity: 0;
}
.v-enter-to {
  opacity: 1;
}

/* 非表示アニメーション */
.v-leave {
  opacity: 1;
}
.v-leave-to {
  opacity: 0;
}
</style>

<script>

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    }
  }
}
</script>
